<template>
<div class="class_list">
    <q-table :rows="class_list" separator="cell" flat bordered dense
             :rows-per-page-options="[0]" row-key="class_id" 
             table-header-class="bg-blue-1"
             class="sticky-header-table" style="height:300px" 
             :key="key_class" >
        <template v-slot:header>
            <tr class="bg-blue-1">
                <th class="w40px">
                    <q-checkbox dense v-model="is_selected_all" @update:model-value="change_select_all" />
                </th>
                <th class="text-left"> 
                    반이름
                </th>
            </tr>
        </template>
        <template v-slot:body="props">
            <tr class="pointer"
                :class="{active:props.row.is_selected}" @click="click_select(props.row)">
                <td>
                    <q-checkbox dense v-model="props.row.is_selected" @update:model-value="change_select(props.row)"/>
                </td>
                <td class="v-middle"> 
                    <class_name_renderer :key="props.row.class_id" :row="props.row" ></class_name_renderer>
                </td>
            </tr>
        </template>
        <template v-slot:bottom>
                <q-pagination
                    v-model="pagination.page"
                    :max="pagination.total_pages"
                    size="sm"
                    input
                    @update:model-value="change_pagination"
                />
        </template>
    </q-table>

</div>
</template>


<script>
import class_name_renderer from '@_shared/class/class_name_renderer.vue';

export default {
    components : {
        class_name_renderer,
    },
    props : {
        form : Object,
    },
    data : function() {
        return {
            self : this,
            key_class : 1,
            class_list : [],
            pagination : {
                page: 1,
                per_page : 20,
                total_pages: 0,
                total_count: 0
            },
            class_type_list : [],
            is_selected_all:false,
        }
    },
    methods : {
        
        /* click */
        click_select(row) {
            row.is_selected=!row.is_selected; //토글
            this.check_select_all();
            this.change_selected();
        },
        
        /* select */
        change_select() {
            this.check_select_all();
            this.change_selected();
        },
        change_select_all() {
            this.class_list.forEach(x => {
                x.is_selected = this.is_selected_all;
            });
            this.change_selected();
        },
        check_select_all() {
            let is_all = true;
            this.class_list.forEach(x => {
                if(x.is_selected == false) {
                    is_all = false;
                }
            });
            this.is_selected_all = is_all;
        },
        clear_selection() {
            this.class_list.forEach(x => {
                x.is_selected = false;
            });
            this.is_selected_all = false;
            this.change_selected();
        },
        
        /* change */
        change_class_type : function(class_type_list) {
            this.class_type_list = class_type_list;
            this.load_class_list(1);
        },
        change_selected() {
            let selected = [];
            this.class_list.forEach(x => {
                if(x.is_selected) {
                    selected.push(x);
                }
            });
            this.$emit('selected_class', selected);
        },
        
        /* date */
        format_date : function(datetime) {
            if(!datetime) datetime='';
            var date = datetime.toLocalDate();
            if(date) { return this.$c.formatDate(date,this.$store.state.date_format); }
            return "";
        },
        format_time : function(datetime) {
            if(!datetime) datetime='';
            var date = datetime.toLocalDate();
            if(date) { return this.$c.formatDate(date,this.$store.state.time_format); }
            return "";
        },
        
        /* load */
        change_pagination(page) {
            this.load_class_list(page);
        },
        
        load_class_list : function(page) {
            let vm = this;
            /*
            let class_type_id_list = this.class_type_list.map( x=>{
                return x.class_type_id;
            });
            let url = '/api/admin/app/aca_erp/classes/class_types/classes';
            */
            //하나만 선택이라서 바꿈
            let class_type_id = 0;
            if(this.class_type_list.length>0) {
                class_type_id = this.class_type_list[0].class_type_id;
            }
            let url = `/api/admin/app/aca_erp/classes/class_types/${class_type_id}/classes`;
            let filter = {};
            filter['class_type_id'] = {"filterType": "number","type": "=","filter": class_type_id };
            if(!vm.form.include_close_class) {
                filter['is_alive'] = {"filterType": "number","type": "=","filter": 1};
            }
            let form_data = {
                page : page,
                limit : vm.pagination.per_page,
                filter : JSON.stringify(filter),
                sort : '[]',
                tzoffset : this.$c.tzoffset,
            };
            this.$c.io.get(url, form_data).then( function(response) {
                var data = response.data;
                if(data['success']) {
                    for(let i=0; i<data['class_list'].length; i++) {
                        var p = data['class_list'][i];
                        p.time_added = vm.format_time(p.date_added);
                        p.date_added = vm.format_date(p.date_added);
                        p.time_started = vm.format_time(p.date_started);
                        p.date_started = vm.format_date(p.date_started);
                        p.time_closed = vm.format_time(p.date_closed);
                        p.date_closed = vm.format_date(p.date_closed);
                        //반의 과목당 lecture설정 데이터 세팅
                        for(let j=0; j<p.course_list.length; j++) {
                            let course = p.course_list[j];
                            course.course_config_list = [];
                            for(let k=0; k<p.course_config_list.length; k++) {
                                let lecture_cfg = p.course_config_list[k];
                                if(course.class_course_id == lecture_cfg.class_course_id) {
                                    course.course_config_list.push(lecture_cfg);
                                }
                            }
                        }
                    }
                    vm.class_list = data['class_list'];
                    vm.pagination.page = data['pagination'].page;
                    vm.pagination.total_count = data['pagination'].total_count;
                    vm.pagination.total_pages = data['pagination'].total_pages;
                    //console.log(data['class_list']);
                    // 테이블 화면 갱신
                    vm.key_class += 1;
                }
                vm.clear_selection();
            });
        },
    },
    beforeMount : function() {
        this.$emitter.on("change_class_type", this.change_class_type);
    },
    mounted : function() {
    },
    beforeUnmount : function() {
        this.$emitter.off("change_class_type");
    },
}
</script>
