<template>
        <div>
            <div>* 남은건수 *</div>
            <table class="border">
            <tr><td>단문 : </td><td class="text-right">{{format_number(SMS_CNT)}}</td></tr>
            <tr><td>장문 : </td><td class="text-right">{{format_number(LMS_CNT)}}</td></tr>
            <tr><td>그림문자 : </td><td class="text-right">{{format_number(MMS_CNT)}}</td></tr>
            </table>
        </div>
</template>

<script>
export default {
    components : {
    },
    data : function() {
        return {
            LMS_CNT : 0,
            MMS_CNT : 0,
            SMS_CNT : 0,
        }
    },
    computed : {
        center_api() {
            return this.$store.state.center_api;
        },
    },
    methods : {
        /* format */
        format_number(n) {
            let number = this.$c.toInt(n);
            return number.format()
        },
        
        /* load */
        load_message_info() {
            let vm = this;
            let msg_company = this.center_api.msg_company;
            this.$c.io.get(`/api/admin/app/aca_erp/util/message/agent/${msg_company}/info`, {}).then(function(response) {
                let data = response.data;
                if(data.success) {
                    vm.LMS_CNT = data.info.LMS_CNT;
                    vm.MMS_CNT = data.info.MMS_CNT;
                    vm.SMS_CNT = data.info.SMS_CNT;
                }
            });
        },
    },
    beforeMount : function() {
        this.load_message_info();
    },
    mounted : function() {
        
    },
    beforeUnmount : function() {
    },
}
</script>
